<template>
  <div class="pa-5">
    <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="20"
        sort-by="equipamentoText"
        class="elevation-1"
        :search="search"
        style="padding-top: 20px"
        id="my-table"
    >
      <template v-slot:top>

        <v-toolbar flat class="mb-3">
          <router-link :to="{ name: 'geral-usuario' }" style="color: #888888; text-decoration: none;">Usuário</router-link>
          <v-icon>mdi-chevron-right</v-icon>
          <a href="javascript:void(0)" style="color: #888888; text-decoration: none;">Estabelecimento</a>
        </v-toolbar>

        <v-toolbar flat class="mb-3">
          <v-text-field v-model="search" label="Search" class="mt-8" outlined append-icon="mdi-magnify"></v-text-field>
        </v-toolbar>

        <v-toolbar flat>

          <v-toolbar-title>
            <span>ESTABELECIMENTOS</span><br>
            <small style="color: #888888; font-size: 12px;">
              {{ usuario.name }}
            </small>
          </v-toolbar-title>

        </v-toolbar>

        <v-toolbar flat>
          <dialog-create-usuario-estabelecimento-component
              :sendDialog="dialog"
              :sendEditedIndex="editedIndex"
              :sendEditedItem="editedItem"
              :sendUsuario="usuario"
              :sendModelsFormCreate="modelsFormCreate"
              @returnDialog="dialog = $event"
              @returnEditedIndex="editedIndex = $event"
              @returnMessage="message = $event"
              @returnSnackbar="snackbar = $event"
              @returnInitialize="initialize"
          ></dialog-create-usuario-estabelecimento-component>

          <dialog-delete-usuario-estabelecimento-component
              :sendEditedIndex="editedIndex"
              :sendDialogDelete="dialogDelete"
              @returnDialogDelete="dialogDelete = $event"
              @returnEditedIndex="editedIndex = $event"
              @returnMessageDel="message = $event"
              @returnSnackbar="snackbar = $event"
              @returnInitialize="initialize"
          ></dialog-delete-usuario-estabelecimento-component>
        </v-toolbar>

      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-icon small class="mr-2" v-if="item.ativo == 1">mdi-check</v-icon>
        <v-icon small class="mr-2" @click="ativar(usuario.id, item.estabelecimento_id)" v-else title="Ativar">mdi-cancel</v-icon>
        <v-icon small class="mr-2" @click="editItem(item)" v-if="admin == 1">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)" v-if="admin == 1">mdi-delete</v-icon>

      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>

    </v-data-table>

    <snackbar-component :sendSnackbar="snackbar" :message="message" @returnSnackbar="snackbar = $event"></snackbar-component>
    <loading-component :overlay="overlay"></loading-component>

  </div>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";
import SnackbarComponent from "@/components/snippets/SnackbarComponent";
import DialogCreateUsuarioEstabelecimentoComponent from "@/components/geral/usuarios-estabelecimentos/DialogCreateUsuarioEstabelecimentoComponent.vue";
import DialogDeleteUsuarioEstabelecimentoComponent from "@/components/geral/usuarios-estabelecimentos/DialogDeleteUsuarioEstabelecimentoComponent.vue";
import session from "js-cookie";

export default {
  components: {
    LoadingComponent,
    SnackbarComponent,
    DialogCreateUsuarioEstabelecimentoComponent,
    DialogDeleteUsuarioEstabelecimentoComponent,
  },
  data: () => ({

    dialog: false,
    dialogDelete: false,
    search: '',
    snackbar: false,
    message: {sucesso: false, erro: false},
    overlay: false,
    headers: [
      { text: 'Estabelecimento', align: 'start', sortable: false, value: 'estabelecimento_nome' },
      { text: 'Nível', align: 'start', sortable: false, value: 'usuario_nivel' },
      { text: 'Ações', value: 'actions', sortable: false, width: '100px' },
    ],
    editedItem: {
      descricao: '',
    },
    defaultItem: {
      descricao: '',
    },
    desserts: [],
    editedIndex: 0,
    modelsFormCreate: [],
    usuario: '',
    admin: session.get('_admin'),
  }),
  created() {

    this.initialize()
  },
  methods: {

    initialize(){

      this.overlay = true

      model.apiGet('usuario-estabelecimento', this.$route.params.id).then(response => {

        this.overlay = false

        this.usuario = response.data.usuario
        this.desserts = response.data.usuario.estabelecimentos
        this.modelsFormCreate = response.data.modelos

        if(this.usuario.id == session.get('_usuario_id'))
        {
          for(let i = 0; i < response.data.usuario.estabelecimentos.length; i++){

            if(response.data.usuario.estabelecimentos[i].ativo == 1){

              session.set('_escola_id', response.data.usuario.estabelecimentos[i].estabelecimento_id)
              session.set('_escola_nome', response.data.usuario.estabelecimentos[i].estabelecimento_nome)
              this.$emit('returnEscola', session.get('_escola_nome'))
            }
          }
        }
      })
    },
    editItem(item){

      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {

      this.editedIndex = item.id
      this.dialogDelete = true
    },
    ativar(usuario, estabelecimento){

      this.overlay = true

      model.apiPut('usuario-estabelecimento/ativar', usuario, {ativo: true, estabelecimento: estabelecimento}).then(response => {

        this.overlay = false
        this.message = response

        if(!response.errors){

          this.message = response.data
          this.initialize()
          this.snackbar = true
        }
      })
    },
  },
}
</script>

<style scoped>

</style>

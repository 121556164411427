import jsPDF from "jspdf"
import 'jspdf-autotable'
import cabecalho from '@/relatorios/header'

export default {

    run(item){

        let header = []
        header.push(cabecalho.run())

        let relatorio = []

        relatorio.push([{ content: item.descricao, colSpan: 6, styles: { halign: 'center' }}])

        relatorio.push(
            [
                { content: item.titulos[0], styles: { halign: 'center' }},
                { content: item.titulos[1], styles: { halign: 'center' }},
                { content: item.titulos[2], styles: { halign: 'center' }},
                { content: item.titulos[3], styles: { halign: 'center' }},
                { content: item.titulos[4], styles: { halign: 'center' }},
                { content: item.titulos[5], styles: { halign: 'center' }},
            ])

        if(item.candidatos.length > 0)
        {
            for(let i = 0; i < item.candidatos.length; i++){

                relatorio.push(
                    [
                        { content: item.candidatos[i]['ordem'], styles: { halign: 'center' }},
                        { content: item.candidatos[i]['nome'], styles: { halign: 'left' }},
                        { content: item.candidatos[i]['cpf'], styles: { halign: 'center' }},
                        { content: item.candidatos[i]['processo'], styles: { halign: 'center' }},
                        { content: item.candidatos[i]['matricula'], styles: { halign: 'center' }},
                        { content: item.candidatos[i]['total'], styles: { halign: 'center' }},
                    ])
            }
        }

        const doc = new jsPDF()

        //Tabela do cabeçalho
        doc.autoTable({theme: 'plain', body: header, didDrawCell: (data) => {
                if (data.section === 'body' && data.column.index === 0) {
                    var base64Img = cabecalho.logo()
                    doc.addImage(base64Img, 'JPEG', data.cell.x + 3, data.cell.y, 30, 20)
                }
            },})

        //Tabela do corpo
        doc.autoTable({theme: 'grid', body: relatorio })

        doc.output('dataurlnewwindow')
    },
}

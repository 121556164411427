<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        :fullscreen="true"
    >

      <template v-slot:activator="{ on, attrs }" v-if="admin == 1">
        <v-row>
          <v-col cols="2">
            <v-btn color="success" dark class="ml-3" v-bind="attrs" v-on="on" large>
              <v-icon>mdi-plus</v-icon>
              Alteração
            </v-btn>
          </v-col>

          <v-col cols="2">
            <v-btn color="secondary" dark class="ml-3" v-bind="attrs" @click="relatorio" large>
              <v-icon>mdi-file</v-icon>
              Relatório
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <v-card>
        <v-card-title>
          <v-icon>mdi-calendar-month</v-icon>
          <span class="text-h5 ml-2" style="color: #666666;">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>

          <v-tabs
              v-model="tab"
              background-color="grey"
              center-active
              dark
          >
            <v-tab href="#informacoes-gerais">INFORMAÇÕES GERAIS</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="mt-8" style="height: 61vh; overflow-y: auto;">
            <v-tab-item value="informacoes-gerais" style="padding-top: 5px;">
              <v-row dense>

                <v-col cols="12" md="9">
                  <v-text-field
                      v-model="editedItem.nome"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Nome
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                      v-model="editedItem.data_nascimento"
                      v-mask="'##/##/####'"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Data de Nascimento
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                      v-model="editedItem.cpf"
                      v-mask="'###.###.###-##'"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>CPF
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                      v-model="editedItem.admissao"
                      v-mask="'##/##/####'"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Data de admissão
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                      v-model="editedItem.numero_matricula"
                      v-mask="'###########'"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Número da matrícula
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                      v-model="editedItem.numero_processo"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Número do processo
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                      label="Eficiência"
                      v-model="editedItem.eficiencia"
                      v-mask="'##'"
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      label="Assiduidade"
                      v-model="editedItem.assiduidade"
                      v-mask="'##'"
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      label="Pontualidade"
                      v-model="editedItem.pontualidade"
                      v-mask="'##'"
                      outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                      :label="typeof modelsFormCreate.exercicios != 'undefined' ? modelsFormCreate.exercicios[0].descricao : ''"
                      v-model="editedItem.magisterio_unidade_escolar"
                      v-mask="'##'"
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      :label="typeof modelsFormCreate.exercicios != 'undefined' ? modelsFormCreate.exercicios[1].descricao : ''"
                      v-model="editedItem.magisterio_publico_municipal"
                      v-mask="'##'"
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      :label="typeof modelsFormCreate.exercicios != 'undefined' ? modelsFormCreate.exercicios[2].descricao : ''"
                      v-model="editedItem.funcionalismo_publico_municipal"
                      v-mask="'##'"
                      outlined
                  ></v-text-field>
                </v-col>
                <!--Fim-->

              </v-row>
            </v-tab-item>

          </v-tabs-items>

          <v-col cols="12" class="text-right">
            <v-btn color="red darken-1" text @click="close">
              Fechar
            </v-btn>
            <v-btn color="green darken-1" text @click="save">
              Salvar
            </v-btn>
          </v-col>

        </v-card-text>
      </v-card>

      <loading-component :overlay="overlay"></loading-component>

    </v-dialog>
  </v-row>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";
import session from "js-cookie";
import relClassificacaoFinal from "@/relatorios/jornada-trabalho/classificacao-final"

export default {
  components: {
    LoadingComponent,
  },
  props: [
    'sendDialog',
    'sendEditedIndex',
    'sendEditedItem',
    'sendModelsFormCreate',
    'sendAdmin',
  ],
  data: () => ({
    tab: null,
    dialog: false,
    message: '',
    editedItem: {
      nome: '',
      data_nascimento: '',
      cpf: '',
      admissao: '',
      numero_matricula: '',
      numero_processo: '',
      eficiencia: 0,
      assiduidade: 0,
      pontualidade: 0,
      magisterio_unidade_escolar: 0,
      magisterio_publico_municipal: 0,
      funcionalismo_publico_municipal: 0,
    },
    defaultItem: {
      nome: '',
      data_nascimento: '',
      cpf: '',
      admissao: '',
      numero_matricula: '',
      numero_processo: '',
      eficiencia: 0,
      assiduidade: 0,
      pontualidade: 0,
      magisterio_unidade_escolar: 0,
      magisterio_publico_municipal: 0,
      funcionalismo_publico_municipal: 0,
    },
    editedIndex: 0,
    overlay: false,
    modelsFormCreate: [],
    admin: session.get('_admin'),
  }),
  created(){

    //
  },
  computed: {

    formTitle () {

      return (this.editedIndex <= 0 ? 'Mais ' : ' Editar ') + ' Alteração de Jornada de Trabalho'
    },
  },
  watch: {

    sendDialog (val) { this.dialog = val },

    sendEditedIndex (val) { this.editedIndex = val },

    sendEditedItem (val) {this.editedItem = val },

    sendModelsFormCreate (val) { this.modelsFormCreate = val },

    sendAdmin (val) { this.admin = val },
  },
  methods: {

    close(){

      this.dialog = false
      this.$emit('returnDialog', false)

      this.$nextTick(() => {

        this.editedItem = Object.assign({}, this.defaultItem)
        this.$emit('returnEditedIndex', 0)
        this.$emit('returnInitialize')
      })
    },
    save(){

      this.overlay = true

      if(this.editedIndex > 0){

        model.apiPut('alteracao-jornada-trabalho/update', this.editedIndex, this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response.data)
          this.$emit('returnSnackbar', true)

          if(typeof response.data.sucesso != 'undefined'){

            if(this.editedIndex == session.get('_usuario_id'))
            {
              this.setAdmin()
            }

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
      else{

        model.apiPost('alteracao-jornada-trabalho/store', this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response)
          this.$emit('returnSnackbar', true)

          if(typeof response.sucesso != 'undefined'){

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
    },
    setAdmin(){

      model.apiGet('set-admin', this.editedIndex).then(response => {

        session.set('_admin', response.data.admin)
        this.$emit('returnAdmin', response.data.admin)
      })
    },
    relatorio(){

      this.overlay = true

      model.apiGet('pdf/classificacao-final').then(response => {

        this.overlay = false

        relClassificacaoFinal.run(response.data)
      })
    }
  },
}
</script>

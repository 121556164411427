import axios from 'axios'
import session from 'js-cookie'

axios.defaults.baseURL = 'https://api.professores.devalx.com.br/public/api'
axios.defaults.headers.common['Authorization'] = session.get('_token')
axios.defaults.headers.common['Accept'] = 'application/json'

export default {

    apiLogin(email, password)
    {
        return axios.post('login', {email: email, password: password}).then(response => {

            let token = response.data.token.original.token_type + " " + response.data.token.original.access_token

            session.set('_token', token)
            session.set('_usuario_id', response.data.user.id)
            session.set('_usuario_nome', response.data.user.name)
            session.set('_admin', response.data.admin)
            session.set('_nivel_id', response.data.nivel.id)
            session.set('_nivel_descricao', response.data.nivel.descricao)
            session.set('_anoletivo', response.data.anoletivo)
            session.set('_dialog', true)

            return response.data.message

        }).catch(error => {

            return {erro: error.response.data.error}
        })
    },

    apiLogout(){

        return axios.post('logout').then(response => {

            session.remove('_token')
            return response.data
        })
    },

    apiGet(uri, id = false)
    {
        if(id)
        {
            uri = uri + '/' + id
        }

        return axios.get(uri).then((response) => {

            return response

        }).catch(error => {

            return error.response
        })
    },

    apiPost(uri, data){

        return axios.post(uri, data).then((response) => {

            return response.data

        }).catch(error => {

            return error.response.data
        })
    },

    apiPut(uri, id, data){

        data._method = 'PUT'

        uri = uri + '/' + id

        return axios.put(uri, data).then((response) => {

            return response

        }).catch(error => {

            return error.response.data
        })
    },

    apiDel(uri, id){

        uri = uri + '/' + id

        return axios.delete(uri, {_method: 'DELETE'}).then((response) => {

            return response
        }).catch(error => {

            return error.response.data
        })
    }
}

<template>
  <div class="pa-5">
    <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="20"
        sort-by="nome"
        class="elevation-1"
        :search="search"
        style="padding-top: 20px"
        id="my-table"
    >
      <template v-slot:top>

        <v-toolbar flat class="mb-3">
          <v-text-field v-model="search" label="Search" class="mt-8" outlined append-icon="mdi-magnify"></v-text-field>
        </v-toolbar>

        <v-toolbar flat>

          <v-toolbar-title>
            <span>ANOS LETIVOS</span><br>
          </v-toolbar-title>

        </v-toolbar>

        <v-toolbar flat>
          <dialog-create-ano-letivo-component
              :sendDialog="dialog"
              :sendEditedIndex="editedIndex"
              :sendEditedItem="editedItem"
              :sendAdmin="admin"
              @returnDialog="dialog = $event"
              @returnEditedIndex="editedIndex = $event"
              @returnMessage="message = $event"
              @returnSnackbar="snackbar = $event"
              @returnInitialize="initialize"
              @returnAdmin="admin = $event"
          ></dialog-create-ano-letivo-component>

          <dialog-delete-ano-letivo-component
              :sendEditedIndex="editedIndex"
              :sendDialogDelete="dialogDelete"
              @returnDialogDelete="dialogDelete = $event"
              @returnEditedIndex="editedIndex = $event"
              @returnMessageDel="message = $event"
              @returnSnackbar="snackbar = $event"
              @returnInitialize="initialize"
          ></dialog-delete-ano-letivo-component>
        </v-toolbar>

      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" v-if="item.anoletivo == anoletivo">mdi-check</v-icon>
        <v-icon small class="mr-2" @click="ativar(item.anoletivo)" v-else>mdi-cancel</v-icon>
        <v-icon small class="mr-2" @click="editItem(item)" v-if="admin == 1">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)" v-if="admin == 1">mdi-delete</v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>

    </v-data-table>

    <snackbar-component :sendSnackbar="snackbar" :message="message" @returnSnackbar="snackbar = $event"></snackbar-component>

    <loading-component :overlay="overlay"></loading-component>

  </div>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";
import SnackbarComponent from "@/components/snippets/SnackbarComponent";
import DialogCreateAnoLetivoComponent from "@/components/configurando/ano-letivo/DialogCreateAnoLetivoComponent.vue";
import DialogDeleteAnoLetivoComponent from "@/components/configurando/ano-letivo/DialogDeleteAnoLetivoComponent.vue";
import session from "js-cookie";

export default {
  components: {
    LoadingComponent,
    SnackbarComponent,
    DialogCreateAnoLetivoComponent,
    DialogDeleteAnoLetivoComponent,
  },
  data: () => ({

    dialog: false,
    dialogDelete: false,
    search: '',
    snackbar: false,
    message: {sucesso: false, erro: false},
    overlay: false,
    headers: [
      {text: 'Ano Letivo', align: 'start', sortable: true, value: 'anoletivo'},
      { text: 'Actions', align: 'end', value: 'actions', sortable: false },
    ],
    editedItem: {},
    desserts: [],
    editedIndex: 0,
    admin: session.get('_admin'),
    anoletivo: '',
  }),
  created() {

    this.initialize()
  },
  watch: {

    admin(val) { this.$emit('returnAdmin', val) }
  },
  methods: {

    initialize(){

      this.overlay = true

      model.apiGet('anosletivos').then(response => {

        this.anoletivo = session.get('_anoletivo')
        this.$emit('returnAnoLetivo', this.anoletivo)

        this.overlay = false
        this.desserts = response.data.anosletivos

        for(let i = 0; i < response.data.anosletivos.length; i++){

          if(response.data.anosletivos[i].ativo == 1){

            session.set('_ano_letivo_id', response.data.anosletivos[i].id)
            session.set('_ano_letivo', response.data.anosletivos[i].anoletivo)
          }
        }
      })
    },
    editItem(item){

      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {

      this.editedIndex = item.id
      this.dialogDelete = true
    },
    ativar(anoletivo){

      session.set('_anoletivo', anoletivo)
      this.initialize()
    },
  },
}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./AdministrandoJornadaTrabalhoComponent.vue?vue&type=template&id=0c191d74&scoped=true&"
import script from "./AdministrandoJornadaTrabalhoComponent.vue?vue&type=script&lang=js&"
export * from "./AdministrandoJornadaTrabalhoComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c191d74",
  null
  
)

export default component.exports
<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        :fullscreen="true"
    >

      <template v-slot:activator="{ on, attrs }" v-if="admin == 1">
        <v-row>
          <v-col cols="12">
            <v-btn color="success" dark class="ml-3" v-bind="attrs" v-on="on" large>
              <v-icon>mdi-plus</v-icon>
              Estabelecimento
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <v-card>
        <v-card-title>
          <v-icon>mdi-calendar-month</v-icon>
          <span class="text-h5 ml-2" style="color: #666666;">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>

          <v-tabs
              v-model="tab"
              background-color="grey"
              center-active
              dark
          >
            <v-tab href="#identificacao" style="text-align: left;">
              {{ usuario.name }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="mt-8" style="height: 61vh; overflow-y: auto;">
            <v-tab-item value="identificacao" style="padding-top: 5px;">
              <v-row dense>

                <v-col cols="8">
                  <v-autocomplete
                      v-model="editedItem.estabelecimento_id"
                      :items="modelsFormCreate.estabelecimentos"
                      item-value="id"
                      item-text="nome"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Estabelecimento
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                      v-model="editedItem.nivel_id"
                      :items="modelsFormCreate.niveis"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Nível
                    </template>
                  </v-autocomplete>
                </v-col>

              </v-row>
            </v-tab-item>
          </v-tabs-items>

          <v-col cols="12" class="text-right">
            <v-btn color="red darken-1" text @click="close">
              Fechar
            </v-btn>
            <v-btn color="green darken-1" text @click="save">
              Salvar
            </v-btn>
          </v-col>

        </v-card-text>
      </v-card>

      <loading-component :overlay="overlay"></loading-component>

    </v-dialog>
  </v-row>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";
import session from "js-cookie";

export default {
  components: {
    LoadingComponent,
  },
  props: [
    'sendDialog',
    'sendEditedIndex',
    'sendEditedItem',
    'sendUsuario',
    'sendModelsFormCreate',
  ],
  data: () => ({
    tab: null,
    dialog: false,
    message: '',
    editedItem: {
      estabelecimento_id: '',
      nivel_id: '',
      ativo: false,
    },
    defaultItem: {
      estabelecimento_id: '',
      nivel_id: '',
      ativo: false,
    },
    editedIndex: 0,
    overlay: false,
    modelsFormCreate: [],
    usuario: {},
    admin: session.get('_admin'),
  }),
  created(){

    //
  },
  computed: {

    formTitle () {

      return (this.editedIndex <= 0 ? 'Mais ' : ' Editar ') + ' Estabelecimento'
    },
  },
  watch: {

    sendDialog (val) { this.dialog = val },

    sendEditedIndex (val) { this.editedIndex = val },

    sendEditedItem (val) { this.editedItem = val },

    sendUsuario (val) { this.usuario = val },

    sendModelsFormCreate (val) { this.modelsFormCreate = val },
  },
  methods: {

    close(){

      this.dialog = false
      this.$emit('returnDialog', false)

      this.$nextTick(() => {

        this.editedItem = Object.assign({}, this.defaultItem)
        this.$emit('returnEditedIndex', 0)
        this.$emit('returnInitialize')
      })
    },
    save(){

      this.overlay = true

      if(this.editedIndex > 0){

        this.editedItem.user_id = this.usuario.id

        model.apiPut('usuario-estabelecimento/update', this.editedIndex, this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response.data)
          this.$emit('returnSnackbar', true)

          if(typeof response.data.sucesso != 'undefined'){

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
      else{

        this.editedItem.user_id = this.usuario.id

        model.apiPost('usuario-estabelecimento/store', this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response)
          this.$emit('returnSnackbar', true)

          if(typeof response.sucesso != 'undefined'){

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
    },
  },
}
</script>

//Middlewares
import guard from '@/middleware/authentication'
import admin from '@/middleware/admin'
//Sistema
import HomeComponent from './../components/home/HomeComponent'
import GeralMenuComponent from '../components/geral/GeralMenuComponent'
import GeralUsuarioComponent from '../components/geral/usuarios/GeralUsuarioComponent.vue'
import GeralUsuarioEstabelecimentoComponent from "@/components/geral/usuarios-estabelecimentos/GeralUsuarioEstabelecimentoComponent.vue";
import ConfigurandoAnoLetivoComponent from '../components/configurando/ano-letivo/ConfigurandoAnoLetivoComponent.vue'
import AdministrandoJornadaTrabalhoComponent from "@/components/administrando/jornada-trabalho/AdministrandoJornadaTrabalhoComponent.vue";
// Áreas comuns
//
export default[
    //Home
    {path: '/', name: 'home', component: HomeComponent},
    //Geral
    {path: '/geral-menu', name: 'geral-menu', component: GeralMenuComponent, beforeEnter:guard.auth},
    {path: '/geral-usuario', name: 'geral-usuario', component: GeralUsuarioComponent, beforeEnter:guard.auth},
    {path: '/geral-usuario-estabelecimento/:id', name: 'geral-usuario-estabelecimento', component: GeralUsuarioEstabelecimentoComponent, beforeEnter:guard.auth},
    //Configurando
    {path: '/configurando-anoletivo', name: 'configurando-anoletivo', component: ConfigurandoAnoLetivoComponent, beforeEnter:guard.auth},
    //Administrando
    {path: '/administrando-jornada-trabalho', name: 'administrando-jornada-trabalho', component: AdministrandoJornadaTrabalhoComponent, beforeEnter:admin.auth},
]

import session from 'js-cookie'

export default{

    auth(to, from, next){

        const token = session.get('_token')
        const admin = session.get('_admin')

        if(!token || admin != 1){

            next('/')
        }

        next()
    }
}
